import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { InlineWidget, PopupModal, useCalendlyEventListener } from "react-calendly";
import client, { logout } from '../../data/client';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Body from '../../components/Body';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useAuthenticated from '../../data/auth/useAuthenticated';
import Image from '../../components/Image';
import { track } from '../../helpers/mixpanel';
import AnimatedTitle from '../../components/AnimatedTitle';
import { Helmet } from 'react-helmet-async';
import HomeViewerQuery from '../../data/queries/HomeViewerQuery';
import UpdateUserHomeMutation from '../../data/mutations/UpdateUserHomeMutation';
import MatchmakerInvitation from './MatchmakerInvitation';
import NetworkMatchmaker from './NetworkMatchmaker';
import NetworkProcess from './NetworkProcess';
import UserBlock from './UserBlock';
import Actions, { ActionType } from './Actions';
import MatchmakingProcess from './MatchmakingProcess';
import MatchmakingPrograms from './MatchmakingPrograms';
import MatchmakerEnd from './MatchmakerEnd';
import BodySection from './BodySection';
import UserWheel from './UserWheel';
import SectionDivider from './SectionDivider';
import MatchmakingQuote from './MatchmakingQuote';
import SectionMessage from './SectionMessage';
import { formatDate } from './helpers';
import MatchmakerCheck from './MatchmakerCheck';
import ClientMatchmaker from './ClientMatchmaker';
import ClientProcess from './ClientProcess';

export default function Home() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useAuthenticated(HomeViewerQuery);
  const survey = searchParams.get('survey');

  const isClient = Boolean(user?.isClient);
  const isApplied = Boolean(user?.appliedAt);
  const isApproved = Boolean(isApplied && user?.approvedAt);
  const isActive = Boolean(isApproved && user?.profileUpdatedAt && user?.preferenceUpdatedAt);
  const isPotentialClient = Boolean(user?.potentialClient);
  const isSurveyedPotentialClient = Boolean(survey || user?.matchmakerSurveyAt);
  const isShowBookPotentialClient = survey === 'book';
  const isBookedPotentialClient = Boolean(user?.matchmakerBookedAt);
  const isPastBookedPotentialClient = user?.matchmakerBookedAt && new Date(user?.matchmakerBookedAt) <= new Date();
  const isDeclinedPotentialClient = Boolean(survey === 'network' || user?.matchmakerDeclinedAt);

  const declinedPotentialClient = useCallback(async () => {
    await client.mutate({
      mutation: UpdateUserHomeMutation,
      variables: {
        fields: {
          matchmakerDeclinedAt: new Date(),
        },
      },
      fetchPolicy: 'no-cache',
    });
    location.reload(); 
  }, []);

  const animate = isClient || (isActive 
    && !isShowBookPotentialClient
    && (!isPotentialClient || isSurveyedPotentialClient || isDeclinedPotentialClient));
  const duration = animate ? 3 : 0;

  // save booked time with matchmaker on backend.
  useEffect(() => {
    if (survey) {
      const elligable = { network: 'not eligable', matchmaker: 'eligable', book: 'highly eligable' }[survey] || 'unsure';
      if (survey) {
        track('matchmaker_survey', { elligable });
      }
      // Update user on backend if they have done the survey or not.
      client.mutate({
        mutation: UpdateUserHomeMutation,
        variables: {
          fields: {
            ...survey && {
              matchmakerSurveyAt: new Date(),
              matchmakerSurvey: elligable,
            },
            ...survey === 'network' && {
              matchmakerDeclinedAt: new Date(),
            },
          },
        },
      });
    }
  }, []);

  const [showBookPlan, setShowBookPlan] = useState<string | null>(null);

  const handleBookCall = useCallback(async (plan: string = 'none') => {
    track('click_book_call');
    setShowBookPlan(plan);
  }, []);

  const handleStartDiscover = useCallback(async () => {
    track('click_start_discover');
    location.href = `https://pay.relate-matchmaking.com/cart/48146612814157:1?checkout=true`;
  }, []);

  const handleMatchmakerDecline = useCallback(async () => {
    await client.mutate({
      mutation: UpdateUserHomeMutation,
      variables: {
        fields: {
          matchmakerDeclinedAt: null,
        },
      },
      fetchPolicy: 'no-cache',
    });
    location.reload(); 
  }, []);

  useCalendlyEventListener({
    onDateAndTimeSelected: () => track("book_call_time_selected"),
    onEventTypeViewed: () => track("book_call_calendar_viewed"),
    onEventScheduled: async (response) => {
      track("book_call_scheduled");
      const matchmakerBookedUri = response?.data?.payload?.event?.uri;
      // Update user on backend if they have booked or not.
      if (matchmakerBookedUri) {
        await client.mutate({
          mutation: UpdateUserHomeMutation,
          variables: {
            fields: {
              matchmakerBookedUri,
            },
          },
        });
        searchParams.delete('survey');
        setSearchParams(searchParams, { replace: true });
        if (showBookPlan) {
          // location.reload();
          setShowBookPlan(null);
          navigate('/', { state: 'booked' });
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }
    },
  });

  const {
    title,
    sections = [],
  } = useMemo(() => {
    if (isClient) {
      return {
        title: t('home.client_title', { defaultValue: '{{name}}, välkommen till Relate!', name: user?.firstName }),
        sections: [{
          component: UserWheel,
        }, {
          component: Actions,
          actions: [{
            label: t('home_action.edit_profile', 'Redigera din profil'),
            onClick: () => navigate('/application?prefill=true'),
          }] as ActionType[],
        }, ClientProcess, /* NextQuiz ,*/ ...isActive ? [ClientMatchmaker] : [], {
          component: Actions,
          actions: [{
            label: t('logout', 'Logga ut'),
            onClick: () => logout(),
          }],
        }],
      };
    }
    if (!isApplied) {
      return {
        title: user?.firstName
          ? t('home.unapplied_title', { defaultValue: '{{name}}, gå med i nätverket idag!', name: user?.firstName })
          : t('home.unapplied_noname_title', { defaultValue: 'Gå med i nätverket idag!', name: user?.firstName }),
        sections: [{
          component: UserBlock,
          description: t('home_block.unapplied_description', `Med medlemskap kan våra matchmakers matcha dig med andra i vårt nätverk. Du kan även ställa dig i kö för att få en egen personlig matchmaker.`),
          label: t('home.unapplied_label', 'Ansökan ej skickad'),
          labelType: 'error',
        }, {
          component: Actions,
          animate: false,
          actions: [{
            label: t('home_action.unapplied_label', 'Gå med idag'),
            help: t('home.unapplied_help', 'Medlemskap i nätverket är kostnadsfritt'),
            onClick: () => navigate('/application?prefill=true'),
            primary: true,
          }] as ActionType[],
        }],
      };
    }
    if (!isApproved) {
      return {
        title: t('home.reviewed_title', { defaultValue: '{{name}}, tack för din ansökan!', name: user?.firstName }),
        sections: [{
          component: UserBlock,
          title: t('home_block.reviewed_title', 'Inväntar granskning'),
          description: t('home_block.reviewed_description', `Din ansökan behöver granskas manuellt, det kan ta lite tid, tar det längre tid än 1 vecka så kontakta gärna vår support på [hello@relate-matchmaking.com](mailto:hello@relate-matchmaking.com).`),
          label: t('home.reviewed_label', 'Inväntar manuell granskning'),
          labelType: 'warning',
        }, {
          component: Actions,
          animate: false,
          actions: [{
            label: t('home_action.change_application_label', 'Redigera din profil'),
            onClick: () => navigate('/application?prefill=true'),
          }] as ActionType[],
        }],
      };
    }
    if (!isActive) {
      return {
        title: t('home.incomplete_title', { defaultValue: '{{name}}, din ansökan är godkänd!', name: user?.firstName }),
        sections: [{
          component: UserBlock,
          title: t('home_block.incomplete_title', 'Gör klart din profil! ✨'),
          description: t('home_block.incomplete_description', 'Aktivera din profil och börja ge våra fantastiska matchmakers allt som krävs för att kunna matcha dig med en likasinnad.'),
          label: t('home.incomplete_label', 'Profil information saknas'),
          labelType: 'warning',
        }, {
          component: Actions,
          animate: false,
          actions: [{
            label: t('home_action.incomplete_label', 'Aktivera din profil'),
            help: t('home.complete_help', 'Aktiveringen av din profil är kostnadsfritt'),
            onClick: () => navigate('/profile'),
            primary: true,
          }] as ActionType[],
        }],
      };
    }
    if (isDeclinedPotentialClient) {
      return {
        title: t('home.declined_potential_network_title', { defaultValue: '{{name}}, välkommen till nätverket!', name: user?.firstName }),
        sections: [{
          component: UserWheel,
        }, {
          component: Actions,
          actions: [{
            label: t('home_action.edit_profile', 'Redigera din profil'),
            onClick: () => navigate('/application?prefill=true'),
          }] as ActionType[],
        }, NetworkProcess, {
          component: MatchmakerInvitation,
          handleMatchmakerDecline,
        }, /* NextQuiz ,*/ NetworkMatchmaker, {
          component: Actions,
          actions: [{
            label: t('logout', 'Logga ut'),
            onClick: () => logout(),
          }],
        }],
      };
    }
    if (isPastBookedPotentialClient) {
      return {
        title: t('home.past_booked_potential_network_title', { defaultValue: '{{name}}, hitta din drömperson tillsammans med oss!', name: user?.firstName }),
        sections: [{
          component: UserWheel,
        }, {
          component: Actions,
          actions: [{
            label: t('home_action.edit_profile', 'Redigera din profil'),
            onClick: () => navigate('/application?prefill=true'),
          }] as ActionType[],
        }, , {
          component: BodySection,
          content: t('booked_potential_client_part1', { name: user?.firstName, defaultValue: `### Din match väntar på dig – låt oss introducera er\n\n{{name}}, baserat på din profil och dina svar så tror vi att vi kan hitta din match!\n\nVi har skapat ett fantastiskt 6-månaders program för dig som längtar efter kärleken - och som inte längre vill lämna den åt slumpen. Tillsammans med din personliga matchmaker hjälper vi dig steg-för-steg till drömrelationen.\n\nDet betyder – inget mer svajpande, inga fler algoritmer eller stela dejter (med personer du egentligen inte klickar med). Istället ger vi dig handplockade matchningar utifrån dina preferenser och kompatibilitet, med en expert vid din sida som stöttar, ger råd och finns där hela vägen.\n\n### Det här är för dig som ...\n\n- Längtar efter att dela vardagens små ögonblick med någon\n- Äntligen vill nå en sund och långvarig relation\n- Är redo att hitta din person och börja leva livet du drömmer om` }),
        }, {
          component: BodySection,
          highlight: true,
          content: `## Matchmaking hjälper dig som ...\n\n- Känner dig vilse i dejtingdjungeln\n\n- Inte vill lämna kärleken åt slumpen\n\n- Inte vet vem du vill eller borde träffa\n\n- Aldrig känner att dina dejter klickar helt\n\n- Upplever dejting som en gissningslek\n\n- Dras till ohälsosamma relationer – och vill förändra det\n\n- Saknar modet att vara dig själv när du dejtar.\n\n- Är osäker på dina behov i en relation`,
        }, {
          component: BodySection,
          content: `## Trött på att kyssa grodor?\n\n*Låt oss göra det åt dig*\n\nTillsammans med världsledande matchmakingbyrån Lemarc Thomas, har vi tagit fram ett fantastiskt 6-månaders program. Med en personlig matchmaker hjälper vi dig steg-för-steg till kärleken.\n\n- Vi sållar igenom tusentals personer med dina önskemål i fokus för att hitta de mest intressanta kandidaterna.\n\n- Vi matchar dig endast med personer som delar din framtidsvision och som du har potential att bygga en framtid med.\n\n- Vi kontaktar och intervjuar de mest lovande matchningarna för just dig.\n\n- Vi presenterar enbart de allra bästa matchningarna för dig.\n\n- Vi sätter ihop och planerar dejterna åt dig – du behöver bara dyka upp, vara dig själv och utforska din match.\n\n- Vi samlar feedback från dina dejter för att identifiera och jobba på dina skuggsidor – sånt som håller dig tillbaka från att hitta kärleken idag men som är svårt att få syn på, på egen hand.\n\n- Vi utvärderar tillsammans dina dejter för att finjustera sökandet och ständigt komma närmare den rätta för dig.\n\n- Med en kärleksexpert vid din sida får du någon som stöttar, ger råd och finns där när du behöver det under hela resan.`,
        }, SectionDivider, MatchmakingProcess, MatchmakingQuote, {
          component: BodySection,
          content: `## Lämna inte kärleken åt slumpen\n\nÄr du redo att träffa din match och inte vill lämna kärleken åt slumpen så rekommenderar vi hela vårt 6-månaders program. När du väljer hela vårt 6-månaders program “Hitta rätt” är din plats med din personliga matchmaker garanterad. Den första månaden av programmet ingår även då i priset.\n\nVill du hellre testa på hur det är att ha en personlig matchmaking coach samt ta del av några av matchmaking världens bästa verktyg kan du välja att börja med den första delen av vårt program. Sedan bestämmer du själv om du vill lägga till matchmaking efter det.`,
        }, {
          component: MatchmakingPrograms,
          handleBookCall,
          handleStartDiscover,
        }, {
          component: BodySection,
          content: `Är du redo att radera dejtingapparna och äntligen hitta din person?\n\nVi har Sveriges största matchmaking-nätverk av singlar som är redo att hitta en seriös relation.\n\nDin match väntar på dig – låt oss introducera er!`,
        }, {
          component: MatchmakerEnd,
          handleBookCall,
        }, {
          component: Actions,
          actions: [{
            label: 'Jag vill vara passiv i nätverket',
            onClick: declinedPotentialClient,
          }, {
            label: t('logout', 'Logga ut'),
            onClick: () => logout(),
          }] as ActionType[],
        }],
      };
    }
    if (isShowBookPotentialClient) {
      return {
        title: t('home.book_potential_client_title', { defaultValue: '{{name}}, vi kan hitta din match!', name: user?.firstName }),
        sections: [{
          component: BodySection,
          content: `Baserat på din profil och dina svar så tror vi att vi kan hitta dina match. Du är välkommen att boka in ett kostnadsfritt samtal (ca 10 min). Vi ringer upp dig för ett avslappnat samtal där vi lär oss lite mer om dig och vad du letar efter i en match innan vi utforskar hur våra personliga matchmakers kan hjälpa dig hitta din match.`,
        }, {
          component: () => {
            return (
              <InlineWidget
                url="https://calendly.com/relate_matchmaking/10min"
                pageSettings={{
                  hideEventTypeDetails: true,
                  hideLandingPageDetails: true,
                  hideGdprBanner: true,
                }}
                utm={{
                  utmContent: 'none',
                  utmMedium: 'quiz',
                  utmSource: 'app.relate-matchmaking.com',
                }}
                prefill={{
                  email: user?.email,
                  name: [user?.firstName, user?.lastName].join(' ').trim(),
                  lastName: user?.lastName,
                  location: user?.phone,
                  date: new Date(Date.now() + 86400000)
                }}
                styles={{
                  width: '100vw',
                  height: '1200px',
                  position: 'relative',
                  left: '50%',
                  transform: 'translate(-50%)',
                  marginBottom: -32,
                }}
              />
            );
          },
        }, {
          component: Actions,
          animate: false,
          actions: [{
            label: t('home_book.cancel_label', 'Jag vill inte boka just nu'),
            onClick: () => {
              window.scrollTo({ top: 0, behavior: 'instant' });
              navigate('/');
            },
          }] as ActionType[],
        }],
      };
    }
    if (isBookedPotentialClient) {
      return {
        title: t('home.booked_network_title', { defaultValue: '{{name}}, ditt samtal med oss är bokat!', name: user?.firstName }),
        sections: [{
          component: UserWheel,
        }, {
          component: Actions,
          actions: [{
            label: t('home_action.edit_profile', 'Redigera din profil'),
            onClick: () => navigate('/application?prefill=true'),
          }] as ActionType[],
        }, ...user?.matchmakerBookedAt ? [{
          component: SectionMessage,
          description: t('home_block.booked_matchmaker_description', {
            defaultValue: 'Vårt samtal är bokat *{{date}}*\n\nUnder samtalet (ca 10 min) pratar vi mer om dig och vad du letar efter i en match innan vi utforskar hur våra matchmakers kan hjälpa dig träffa din match.',
            date: formatDate(user?.matchmakerBookedAt),
          }),
        }] : [], {
          component: BodySection,
          content: t('booked_potential_client_part1', { name: user?.firstName, defaultValue: `### Din match väntar på dig – låt oss introducera er\n\n{{name}}, baserat på din profil och dina svar så passar vår personliga matchmaking - och vi tror att vi kan hitta din match!\n\nVi har skapat ett fantastiskt 6-månaders program för dig som längtar efter kärleken - och som inte längre vill lämna den åt slumpen. Tillsammans med din personliga matchmaker hjälper vi dig steg-för-steg till drömrelationen.\n\nDet betyder – inget mer svajpande, inga fler algoritmer eller stela dejter (med personer du egentligen inte klickar med). Istället ger vi dig handplockade matchningar utifrån dina preferenser och kompatibilitet, med en expert vid din sida som stöttar, ger råd och finns där hela vägen.\n\n### Det här är för dig som ...\n\n- Längtar efter att dela vardagens små ögonblick med någon\n- Äntligen vill nå en sund och långvarig relation\n- Är redo att hitta din person och börja leva livet du drömmer om` }),
        }, {
          component: BodySection,
          highlight: true,
          content: `## Matchmaking hjälper dig som ...\n\n- Känner dig vilse i dejtingdjungeln\n\n- Inte vill lämna kärleken åt slumpen\n\n- Inte vet vem du vill eller borde träffa\n\n- Aldrig känner att dina dejter klickar helt\n\n- Upplever dejting som en gissningslek\n\n- Dras till ohälsosamma relationer – och vill förändra det\n\n- Saknar modet att vara dig själv när du dejtar.\n\n- Är osäker på dina behov i en relation`,
        }, {
          component: BodySection,
          content: `## Trött på att kyssa grodor?\n\n*Låt oss göra det åt dig*\n\nTillsammans med världsledande matchmakingbyrån Lemarc Thomas, har vi tagit fram ett fantastiskt 6-månaders program. Med en personlig matchmaker hjälper vi dig steg-för-steg till kärleken.\n\n- Vi sållar igenom tusentals personer med dina önskemål i fokus för att hitta de mest intressanta kandidaterna.\n\n- Vi matchar dig endast med personer som delar din framtidsvision och som du har potential att bygga en framtid med.\n\n- Vi kontaktar och intervjuar de mest lovande matchningarna för just dig.\n\n- Vi presenterar enbart de allra bästa matchningarna för dig.\n\n- Vi sätter ihop och planerar dejterna åt dig – du behöver bara dyka upp, vara dig själv och utforska din match.\n\n- Vi samlar feedback från dina dejter för att identifiera och jobba på dina skuggsidor – sånt som håller dig tillbaka från att hitta kärleken idag men som är svårt att få syn på, på egen hand.\n\n- Vi utvärderar tillsammans dina dejter för att finjustera sökandet och ständigt komma närmare den rätta för dig.\n\n- Med en kärleksexpert vid din sida får du någon som stöttar, ger råd och finns där när du behöver det under hela resan.`,
        }, MatchmakingQuote]
      };
    }
    if (isSurveyedPotentialClient) {
      return {
        title: t('home.surveyed_network_title', { defaultValue: '{{name}}, hitta din drömperson tillsammans med oss!', name: user?.firstName }),
        sections: [{
          component: UserWheel,
        }, {
          component: Actions,
          actions: [{
            label: t('home_action.edit_profile', 'Redigera din profil'),
            onClick: () => navigate('/application?prefill=true'),
          }] as ActionType[],
        }, , {
          component: BodySection,
          content: t('booked_potential_client_part1', { name: user?.firstName, defaultValue: `### Din match väntar på dig – låt oss introducera er\n\n{{name}}, baserat på din profil och dina svar så tror vi att vi kan hitta din match!\n\nVi har skapat ett fantastiskt 6-månaders program för dig som längtar efter kärleken - och som inte längre vill lämna den åt slumpen. Tillsammans med din personliga matchmaker hjälper vi dig steg-för-steg till drömrelationen.\n\nDet betyder – inget mer svajpande, inga fler algoritmer eller stela dejter (med personer du egentligen inte klickar med). Istället ger vi dig handplockade matchningar utifrån dina preferenser och kompatibilitet, med en expert vid din sida som stöttar, ger råd och finns där hela vägen.\n\n### Det här är för dig som ...\n\n- Längtar efter att dela vardagens små ögonblick med någon\n- Äntligen vill nå en sund och långvarig relation\n- Är redo att hitta din person och börja leva livet du drömmer om` }),
        }, {
          component: BodySection,
          highlight: true,
          content: `## Matchmaking hjälper dig som ...\n\n- Känner dig vilse i dejtingdjungeln\n\n- Inte vill lämna kärleken åt slumpen\n\n- Inte vet vem du vill eller borde träffa\n\n- Aldrig känner att dina dejter klickar helt\n\n- Upplever dejting som en gissningslek\n\n- Dras till ohälsosamma relationer – och vill förändra det\n\n- Saknar modet att vara dig själv när du dejtar.\n\n- Är osäker på dina behov i en relation`,
        }, {
          component: BodySection,
          content: `## Trött på att kyssa grodor?\n\n*Låt oss göra det åt dig*\n\nTillsammans med världsledande matchmakingbyrån Lemarc Thomas, har vi tagit fram ett fantastiskt 6-månaders program. Med en personlig matchmaker hjälper vi dig steg-för-steg till kärleken.\n\n- Vi sållar igenom tusentals personer med dina önskemål i fokus för att hitta de mest intressanta kandidaterna.\n\n- Vi matchar dig endast med personer som delar din framtidsvision och som du har potential att bygga en framtid med.\n\n- Vi kontaktar och intervjuar de mest lovande matchningarna för just dig.\n\n- Vi presenterar enbart de allra bästa matchningarna för dig.\n\n- Vi sätter ihop och planerar dejterna åt dig – du behöver bara dyka upp, vara dig själv och utforska din match.\n\n- Vi samlar feedback från dina dejter för att identifiera och jobba på dina skuggsidor – sånt som håller dig tillbaka från att hitta kärleken idag men som är svårt att få syn på, på egen hand.\n\n- Vi utvärderar tillsammans dina dejter för att finjustera sökandet och ständigt komma närmare den rätta för dig.\n\n- Med en kärleksexpert vid din sida får du någon som stöttar, ger råd och finns där när du behöver det under hela resan.`,
        }, SectionDivider, MatchmakingProcess, MatchmakingQuote, {
          component: BodySection,
          content: `## Radera apparna och hitta din match med oss\n\nDu väljer själv vilken väg som känns bäst för dig.\n\nÄr du redo att träffa din match och inte vill lämna kärleken åt slumpen så rekommenderar vi hela vårt 6-månaders program. När du väljer hela vårt 6-månaders program “Hitta rätt” är din plats med din personliga matchmaker garanterad. Den första månaden av programmet ingår även då i priset.\n\nVill du hellre testa på hur det är att ha en personlig matchmaking coach samt ta del av några av matchmaking världens bästa verktyg kan du välja att börja med den första delen av vårt program. Sedan bestämmer du själv om du vill lägga till matchmaking efter det.`,
        }, {
          component: MatchmakingPrograms,
          showBookPlan,
          handleBookCall,
        }, {
          component: BodySection,
          content: `Är du redo att radera dejtingapparna och äntligen hitta din person?\n\nVi har Sveriges största matchmaking-nätverk av singlar som är redo att hitta en seriös relation.\n\nDin match väntar på dig – låt oss introducera er!`,
        }, {
          component: MatchmakerEnd,
          handleBookCall,
        }, {
          component: Actions,
          actions: [{
            label: 'Jag vill vara passiv i nätverket',
            onClick: declinedPotentialClient,
          }, {
            label: t('logout', 'Logga ut'),
            onClick: () => logout(),
          }] as ActionType[],
        }],
      };
    }
    if (isPotentialClient) {
      return {
        title: t('home.potential_client_title', { defaultValue: 'Längtar du efter din livspartner?', name: user?.firstName }),
        sections: [{
          component: BodySection,
          content: `Din match längtar efter dig också.\n\nAtt ha någon att dela vardagen med. Den speciella personen som ni kan bygga er framtid med.\n\n### Du är inte ensam\n\nDu kanske hade hoppats på att du vid det här laget redan hade träffat din stora kärlek. Oavsett vad din erfarenhet av dejting och relationer hittills har varit så förtjänar du en trygg och kärleksfull relation. Du förtjänar att leva det livet du längtar efter – med personen du drömmer om.\n\nDu investerar i alla andra delar av ditt liv – dina vänskapsrelationer, karriär och välmående. Samtidigt vet du innerst inne att du vill hitta din person. Någon att dela livets alla stunder med. Vi förstår att det kan kännas stressigt och frustrerande att vara redo för att träffa sin livspartner men samtidigt inte veta hur.\n\n### Vi guidar dig\n\nVägen till att hitta din livspartner behöver inte vara en gåta. Efter miljontals matchningar, chattar och profiler vet vi vad som krävs för att skapa fungerande långsiktiga och kärleksfulla relationer.\n\nTillsammans med världsledande matchmakingbyrån Lemarc Thomas har vi tagit fram ett program där våra personliga matchmakers steg-för-steg hjälper dig till kärleken. En process som hjälpt hundratals singlar – från att längta efter kärleken, till att vara i relationen de alltid drömt om.\n\nVi kan hjälpa dig med.\n\nDu har gjort på ditt sätt hela ditt liv. Så varför inte prova något nytt?`,
        }, MatchmakerCheck, {
          component: BodySection,
          content: `Om du känner att du har väntat tillräckligt länge med att hitta kärleken och att leva livet som du drömmer om.\n\nVarför vänta ännu längre?\n\nVi hjälper dig hitta din match och relationen du drömmer om.\n\nVi har begränsade antal platser i våra program och med våra matchmakers. Gör vårt korta 1-minut formulär nedan för att ta reda på om du är lämplig för vårt matchmaking-program.\n\nVi accepterar enbart kandidater som verkligen passar vårt program och som vi tror vi kan matcha.`,
        }, {
          component: Actions,
          animate: false,
          actions: [{
            label: 'Gör vår 1-minut formulär',
            primary: true,
            href: `https://relate-app.typeform.com/to/VqVtNuDx#email=${user?.email}&name=${user?.firstName}`,
          }, {
            label: 'Jag vill vara passiv i nätverket',
            onClick: declinedPotentialClient,
          }, {
            label: t('logout', 'Logga ut'),
            onClick: () => logout(),
          }],
        }],
      };
    }
    return {
      title: t('home.network_title', { defaultValue: '{{name}}, välkommen till nätverket!', name: user?.firstName }),
      sections: [{
        component: UserWheel,
      }, {
        component: Actions,
        actions: [{
          label: t('home_action.edit_profile', 'Redigera din profil'),
          onClick: () => navigate('/application?prefill=true'),
        }] as ActionType[],
      }, NetworkProcess, /* NextQuiz ,*/ NetworkMatchmaker, {
        component: Actions,
        actions: [{
          label: t('logout', 'Logga ut'),
          onClick: () => logout(),
        }],
      }],
    };
  }, [user, handleBookCall, isShowBookPotentialClient]);

  const renderSection = useCallback((section, i) => {
    const { component = null, ...props } = typeof section === 'function' ? {} : section;
    const Section = component || section;
    return <Section key={i} {...props} user={user} duration={duration} sx={{ ...i === 0 && { mt: '0 !important' } }} />;
  }, [user, duration]);

  return (
    <Body>
      <Helmet>
        <title>Relate Matchmaking</title>
      </Helmet>
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
        <Image src="/logo.png" sx={{ width: 65 }} />
      </Box>
      {user && (
        <>
          <AnimatedTitle text={title} animate={animate} />
          {sections.map(renderSection)}
          <Box sx={{ display: 'flex', alignItems: 'center', px: 2, py: 4, mb: 2, mt: 4, flexDirection: 'column', borderTop: 'solid 1px #38191E' }}>
            <Image src="/logo.png" sx={{ width: 84 }} />
            <Typography variant="caption">
              {t('slogan')}
            </Typography>
          </Box>
        </>
      )}
      <PopupModal
        url="https://calendly.com/relate_matchmaking/10min"
        pageSettings={{
          hideEventTypeDetails: false,
          hideLandingPageDetails: false,
          hideGdprBanner: true,
        }}
        utm={{
          utmContent: showBookPlan || undefined,
          utmMedium: showBookPlan === 'none' ? 'advice' : 'plans',
          utmSource: 'app.relate-matchmaking.com',
        }}
        prefill={{
          email: user?.email,
          name: [user?.firstName, user?.lastName].join(' ').trim(),
          lastName: user?.lastName,
          location: user?.phone,
          date: new Date(Date.now() + 86400000)
        }}
        onModalClose={() => setShowBookPlan(null)}
        open={Boolean(showBookPlan)}
        /*
          * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
          * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
          */
        rootElement={document.getElementById("root")}
      />
    </Body>
  );
}
